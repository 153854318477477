import React from "react"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { D4, D2, D3 } from "../components/utils/Typography"
import HeroImage from "../images/homeHero.jpg"

const Hero = styled.div`
  min-height: 70vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
`

const HeroCover = styled.div`
  background-color: #12141678;
  min-height: 70vh;
  padding: 0 80px;
  width: 100%;
  text-align: center;
  position: relative;
`

const Header = styled.div`
  margin-top: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 800px;
`

export default class Hire extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Post a Job" />
        <Hero style={{ backgroundImage: `url(${HeroImage})` }}>
          <HeroCover>
            <Container>
              <Header>
                <D2 style={{ color: "white" }} textAlign="center">
                  Payment Successful!
                </D2>
                <D3
                  style={{
                    color: "white",
                    fontWeight: "800",
                  }}
                  textAlign="center"
                >
                  Thank you for choosing JGR! <br />
                  Your post will be up within 48 hours.
                </D3>
              </Header>
            </Container>
          </HeroCover>
        </Hero>
        <Container style={{ minHeight: "15vh", padding: "20px 20px 40px" }}>
          <Row>
            <Col lg="12">
              <D4
                margin="60px 0 10px"
                fontWeight="600"
                style={{ color: "#121416" }}
              >
                Please email{" "}
                <span style={{ fontWeight: "700" }}>
                  hello@jobsgoremote.com
                </span>{" "}
                if you have any questions.
              </D4>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}
